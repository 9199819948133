import React from 'react';
import { Action, DanishMitID_ErrorModel } from '../renditions';
import Frame from '../components/Frame';

import './DanishMitID.css';
import DanishMitIDHeader from './Header';
import { Language } from '@criipto/verify-react';
import { LocalizedTexts } from '../components/Language';
import DanishMitIDFooter from './Footer';

export default function Error(props: {
  model: DanishMitID_ErrorModel,
  action: Action
}) {
  const {model, action} = props;

  return (
    <React.Fragment>
      <DanishMitIDHeader
        action={action}
        language={model.language}
      />
      <Frame className="frame-error centered">
        <div className="mitid-border">
          <label>
            <LocalizedTexts
              texts={
                [
                  {language: 'en', text: `An error occurred`},
                  {language: 'da', text: `Der skete en fejl`}
                ]
              }
            />
          </label>
          <p className="error">{model.error}</p>
          {model.traceId ? (<p className="trace">Trace ID: {model.traceId}</p>) : null}
        </div>
      </Frame>
      <DanishMitIDFooter language={model.language as Language} />
    </React.Fragment>
  );
}
